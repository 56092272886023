<template>
  <div>
    <div v-if="spinner" class="spinner" >
      <img class="spinner--view" src="../assets/aiku_spinner-2.gif" />
    </div>
    <div v-show="!spinner" class="qa">
      <span class="adminTopicModelingDashboard__title">Topic Modeling</span>
      <span class="adminTopicModelingDashboard__details__name">{{projectName}}</span>

      <div class="flex-item">
        <a class="qa__backToTopic--icon" @click="back"></a>
        <input class="qa__topicName" v-model="topicName" maxlength="50" @blur="updateTopicName(topicName)"/>
      </div>

      <!--Channels-->

      <span class="qa__channels--label">{{$t('projectSettings.channel')}}</span>

      <div v-if="dataReady">
        <b-card no-body class="mb-1" v-for="(item, index) in channelsData" :key="index">
          <b-card-header class="qa__channels" header-tag="header" role="tab" >
            <button class="qa__channels--name" block variant="info">{{item.name}}</button>
            <div class="flexGrow1" style="margin-left: 20px">
              <button :class="`qa__channels__saveChanges${(indexOfChannelModified ==index)&& textchanging ? ' showItem': ' hideItem' }`" 
                      @click.prevent="postChannelAnswer(item.id, channelsEditingData[index].answer)" block variant="info"> 
                        <span class="qa__channels__saveChanges--span"> {{$t("admin.modals.save")}} </span>
              </button>
            </div>
            <button class="qa__channels--buttonIcon" v-b-toggle="'accordion-' + item.id" ></button>
          </b-card-header>
          <b-collapse :id="'accordion-' + item.id" accordion="my-accordion" role="tabpanel" @show="initTextAreas()"> 
            <b-card-body :id="'card-' + item.id">
              <textarea :placeholder="$t('qa.channel_placeholder')" class="qa__channels--textarea" 
                :id="'channel-' + item.id" @keyup="textAreaAdjust('channel-' + item.id)" 
                v-model="channelsEditingData[index].answer" @input="showSaveChangesChannel(index)">
              </textarea>
            </b-card-body>
          </b-collapse>
        </b-card> 
      </div>

       <Alert
        :message="$t(alertMessage)"
        ref="alert"
      />

      <!--TAB QA-->

      <div>
        <div class="qa__tab">
          <div class="qa__tab">
            <button :class="`qa__tab--question${activeTab==='questions' ? ' active' : ''}`" @click.prevent="displayQuestions()">{{$t('qa.questions')}}</button>
            <button :class="`qa__tab--answer${activeTab==='answers' ? ' active' : ''}`" @click.prevent="displayAnswers()">{{$t('qa.answers')}}</button>
          </div>
          <div class="qa__tab">
            <span :style="activeTab==='questions' ? 'display:block' : 'display:none'" class="qa__tab--counter"> {{$t('qa.active_questions')}} {{questions.length}}</span>
            <span :style="activeTab==='answers' ? 'display:block' : 'display:none'" class="qa__tab--counter">{{$t('qa.active_answers')}} {{answers.length}}</span>
            <button type="button" class="buttonIcon-plus" @click="addItem()"></button>
          </div>
        </div>

        <!--QUESTIONS-->
        <div :style="activeTab==='questions' ? 'display:block' : 'display:none'" class="qa__tab--visibility">
          <div v-for="(question, index) in questions" :class="`qa__list${index%2  == 0 ? ' pair' : ''}`" :key="question.id">
            <button :class="`qa__list${questions[index].is_training ? '--activeTopicIcon' : '--disableTopicIcon'}`"
                    @click.prevent="postEditedSentence(question.id, questions[index].text, questions[index].is_training ? false : true)">
            </button>
            <button :class="`qa__list${questions[index].is_suggestion ? '--activeStarIcon' : '--disableStarIcon'}`"
                    @click.prevent="postEditedSuggestion(question.id, questions[index].is_suggestion ? false : true)">
            </button>
            <textarea :class="`qa__list--textarea${index%2  == 0 ? ' pair' : ''}`" :id="'question-' + question.id" 
              @keyup="textAreaAdjust('question-' + question.id)"
              v-model="questions[index].text"
              rows="1"
              @input="showSaveChangesQuestionOrAnswer(index)">
            </textarea>
            <div class="qa__moveQuestion--wrapper">
              <button :class="`qa__moveQuestion qa__moveQuestion${selectedQuestionIndex == index && visibility ? ' up' : ''}`" @click="changeVisibility(index)">
                <span class="qa__moveQuestion--span">{{$t("admin.question.move")}}</span>
                <i :class="`qa__moveQuestion${selectedQuestionIndex == index && visibility ? '--arrowUpWhite' : '--arrowDownWhite'}`"></i>
              </button>
              <ul :class="`qa__moveQuestion--list ${selectedQuestionIndex == index && visibility ? 'visibilityTrue' : 'visibilityFalse' }`">
                <input class="qa__moveQuestion--input" :placeholder="$t('admin.search')" @input="provideSearchedTopic($event.target.value)"/>
                <hr>
                <li v-for="(topic, index) in topicsDropDown" class="list-items" :key="index">
                  <div class="qa__moveQuestion--topic" @click="moveTopic(topic.id, question.id)">{{topic.name}}</div>
                </li>
              </ul>
            </div>
            <!-- button for save changes -->
            <button :class="`qa__channels__saveChanges${(indexOfQuestionOrAnswerModified == index)&& textchanging ? ' showItem': ' hideItem' } margin` " 
                      @click.prevent="postEditedSentence(question.id, questions[index].text, questions[index].is_training)">
                       <span class="qa__channels__saveChanges--span"> {{$t("admin.modals.save")}} </span>
             </button>
            <button type="button" class="qa__tab--buttonIcon-delete" @click="deleteItem('question', question.id)"></button>
          </div>
        </div>
        <!---->

        <!--ANSWERS-->
        <div :style="activeTab==='answers' ? 'display:block' : 'display:none'">
          <div v-for="(answer, index) in answers" :class="`qa__list${index%2  == 0 ? ' pair' : ''}`" :key="answer.id">
            <button :class="`qa__list${answers[index].is_selected ? '--activeTopicIcon' : '--disableTopicIcon'}`"
                    @click.prevent="postEditedSentence(answer.id, answers[index].text, answers[index].is_selected ? false : true)">
            </button>
            <textarea :class="`qa__list--textarea${index%2  == 0 ? ' pair' : ''}`" :id="'answer-' + answer.id" 
              @keyup="textAreaAdjust('answer-' + answer.id)" 
              v-model="answers[index].text"
              rows="1"
              @input="showSaveChangesQuestionOrAnswer(index)">
              >
            </textarea>
             <!-- button for save changes -->
            <button :class="`qa__channels__saveChanges${(indexOfQuestionOrAnswerModified == index)&& textchanging ? ' showItem': ' hideItem' } margin` " 
                      @click.prevent="postEditedSentence(answer.id, answers[index].text, answers[index].is_training)">
                       <span class="qa__channels__saveChanges--span"> {{$t("admin.modals.save")}} </span>
             </button>
            <button type="button" class="qa__tab--buttonIcon-delete" @click="deleteItem('answer',answer.id)"></button>
          </div>
        </div>
        <!---->

      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios"; 
import _ from "lodash";
import bus from "@/bus";

import {Alert} from "@/components";

export default {
  name: "Qa",
  components: {
    Alert
  },
  data(){
    return{
      activeProjectID: Number(localStorage.getItem("selectedProjectID")),
      activeTab : 'questions',
      alertMessage: "Alert.Save",
      answers: [],
      channelsData: [],
      channelsEditingData: [],
      channelAnswers: [],
      channelFields:['name'],
      dataReady: false,
      indexOfChannelModified : null,
      indexOfQuestionOrAnswerModified : null,
      textchanging: false,
      topic_id: this.$route.params.id,
      topicName :'',
      topics: [],
      topicsDropDown: [],
      projectName:'',
      questions: [],
      questionsAndAnswers: {},
      visibility: false,
      selectedQuestionIndex: -1,
      searchedTopic : '',
      spinner: true,
      dropDownSuggestions : []
    }
  },

  created() {
      this.authorizeUser();
  },

  methods: {

    back(){
      this.$router.push('/admin') 
    },

    async moveTopic(topicid, questionid){
      console.log({topicid, questionid})
      try {
        await axios.post(`/question/${questionid}`, 
                        { topic_id: topicid 

                        }).then(() => {
                        this.getAllQa(this.topic_id)
         })}
         catch(err) {
        console.log(err)
      }
      
      this.visibility= false
      this.selectedQuestionIndex = -1

    },

    provideSearchedTopic(e){
      this.searchedTopic = e;
      this.dropDownSuggestions  = []
      for (let i of this.topics) {
        if(i.name.toLocaleLowerCase().includes(this.searchedTopic.toLocaleLowerCase()) && this.searchedTopic.length > 2){
          this.dropDownSuggestions.push(i);
          this.topicsDropDown = this.dropDownSuggestions
        }
        if(this.searchedTopic.length < 2){
          this.topicsDropDown = this.topics
        }
      }

    },
    

    changeVisibility(index){
      this.visibility = !this.visibility
      this.selectedQuestionIndex = index
    },

    showSaveChangesChannel(_indexOfChannelModified){
      this.textchanging = true;
      this.indexOfChannelModified = _indexOfChannelModified
    }, 

    showSaveChangesQuestionOrAnswer(_indexOfQuestionOrAnswerModified){
      this.textchanging = true;
      this.indexOfQuestionOrAnswerModified = _indexOfQuestionOrAnswerModified
    },

    async deleteItem(type, id) {
      if (type === 'question') {
        await axios.delete(`/question/${id}`).then(() => {
          this.getAllQa(this.topic_id);
          this.$store.commit("isEditedItems", true);
        });
      } else if (type === 'answer') {
        await axios.delete(`/answer/${id}`).then(() => {
          this.getAllQa(this.topic_id);
        });
      }
    },

    async postEditedSentence(id, text, isActive) {
      try{
        if (this.activeTab === 'questions') {
          await axios.post(`/question/${id}`, {
              is_training : isActive,
              text : text
            })
            .then(() => {
              this.getAllQa(this.topic_id);
              this.$store.commit("isEditedItems", true);
              
            });
        } else if (this.activeTab === 'answers') {
          await axios
            .post(`/answer/${id}`, {
              is_selected: isActive,
              text : text
            })
            .then(() => {
              this.getAllQa(this.topic_id);
            });
        }
      this.alertMessage = "Alert.Save"
      this.$refs.alert.showAlert();
      }catch(err){
        console.log(err)
      }
      
      finally{
        this.indexOfQuestionOrAnswerModified = null
        this.textchanging = false;
        
      }
     
    },

    async postEditedSuggestion(id, isSuggested){
      try{
        await axios.post(`/question/${id}`, {
            is_suggestion : isSuggested
          })
          .then(() => {
            this.getAllQa(this.topic_id);
            // this.$store.commit("isEditedItems", true);
          }); 
        this.alertMessage = "Alert.Save"
        this.$refs.alert.showAlert();
      }catch(err){
        console.log(err)
      }
    },

    async updateTopicName(n) {
      try {
        await axios.post(`/topic/${this.topic_id}`, { name: n });
      }
      catch(err) {
        console.log(err)
      }
    },

    async addItem() {
      if (this.activeTab === 'questions') {
        await axios.put("/question", {
          topic_id: this.topic_id,
          text: " ",
          is_training: false,
        })
        .then(() => {
          this.getAllQa(this.topic_id);
          this.$store.commit("isEditedItems", true);
        });
      } else if (this.activeTab === 'answers') {
        await axios.put("/answer", {
          topic_id: this.topic_id,
          text: " ",
          is_selected: false,
        }).then(() => {
          this.getAllQa(this.topic_id);
        });
      }
    },

    displayAnswers(){
      this.activeTab = 'answers';
    },

    displayQuestions(){
      this.activeTab = 'questions';
    },

    textAreaAnswerAdjust(answerID){
     const tx = document.getElementById("answer-" + answerID);
     tx.setAttribute("style", "height:" + (tx.scrollHeight) + "px;overflow-y:hidden;");
    },

    textAreaAdjust(id){
      const tx = document.getElementById(id);
      tx.style.height = "auto"
      tx.setAttribute("style", "height:" + (tx.scrollHeight) + "px;overflow-y:hidden;");
    },

    authorizeUser() {
      let token;
      if (localStorage || sessionStorage) {
        token = localStorage.getItem("jwt") ? localStorage.getItem("jwt") : sessionStorage.getItem("jwt");
      } else {
        token = cookies.getCookie("jwt");
      }
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    },

    async getChannel(){
      try{
        await axios.get(`/project/${this.activeProjectID}/channels`).then(res => {
            this.channelsData = res.data.data.channels
        
        });

        await axios.get(`/topic/${this.topic_id}`).then(res => {
          this.topicName = res.data.data.name;
        })

        await axios.get(`/project/${this.activeProjectID}`).then(res => {
          this.projectName = res.data.data.name;
        })

        await axios.get(`/project/${this.activeProjectID}/topics`).then(res => {
          this.topics = res.data.data.topics;
          this.topicsDropDown = res.data.data.topics;
        })

        await axios.get(`/topic/${this.topic_id}/channel_answers`).then(res => {
            this.channelAnswers = res.data.data.channel_answers
        });

        for(var i=0; i < this.channelsData.length; i++) {
          let answer_obj = _.find(this.channelAnswers, {'channel_id': this.channelsData[i].id});
          this.channelsData[i].answer = (!answer_obj) ? "" : answer_obj.text;
        };

        this.channelsEditingData = this.channelsData;
        this.dataReady = true
        this.spinner = false
      }catch(err){
        console.log(err)
      }
    },

    async postChannelAnswer(channelID, newText){
      try{
        await axios.post(`/channel/${channelID}/associated_answer`,{
          text: newText,
          project_id: this.activeProjectID,
          topic_id: this.topic_id
        })
        this.$refs.alert.showAlert();
      }catch(err){
        console.log(err)
      }
      finally{
        this.indexOfChannelModified = null
        this.textchanging = false;
      }
     
    },
  
  async getAllQa(){
    await axios.get(`/topic/${this.topic_id}/qa`).then((res) => {
      let q = res.data.data.questions;
      this.questions = _.orderBy(q,['id'],['desc']);
      let a = res.data.data.answers;
      this.answers = _.orderBy(a, ['id'],['desc']);
    })
  },

  onInputTextArea(textAreaID) {
    try{
      const tx = document.getElementById(textAreaID);
      tx.style.height = "auto";
      tx.style.height = (this.scrollHeight) + "px";
    }catch(err){
      console.log(err)
    }
    
  },

  initTextAreas(){
    let tx = document.getElementsByTagName("textarea");
    for (let i = 0; i < tx.length; i++) {
      tx[i].setAttribute("style", "height:" + (tx[i].scrollHeight) + "px;overflow-y:hidden;");
    }
  }
    
  },
  
  async beforeMount(){
    await this.getChannel();
    await this.getAllQa();
 
  },

  updated(){
    this.initTextAreas();
  },

  mounted(){
    this.initTextAreas();
  }
}
</script>
